import * as React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data



function removeTags(str) {
  if ((str===null) || (str===''))
      return false;
  else
      str = str.toString();
      str = str.replaceAll( "</a>", '');
  return str.replace( /(<a([^>]+)>)/ig, '');
}

  let toc = post.tableOfContents
  let TABLEOFCONTENTS = removeTags(toc);
  if(TABLEOFCONTENTS == false){
    TABLEOFCONTENTS = "";
  }else{
    TABLEOFCONTENTS = "<h2>Table of Contents</h2>" + TABLEOFCONTENTS + "<div class='divider'></div>";
  }
  let language = "en";
  if(post.frontmatter.language == "pt"){
    language = "pt";
  }

    return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        lang={language}
      />
      <article
        className="blog-post first-section global-wrapper"
        itemScope
        itemType="http://schema.org/Article"
      >
      <header>
        <h1 itemProp="headline">{post.frontmatter.title}</h1>
        <p>{post.frontmatter.date} | {post.frontmatter.category}</p>
      </header>
      <div
      dangerouslySetInnerHTML={{ __html: TABLEOFCONTENTS }}>
  
      </div>
      <section 
        className="blog-post-section "
        dangerouslySetInnerHTML={{ __html: post.html }}
        itemProp="articleBody"
      />
      <hr />
      






      <footer>
        <Bio />
      <hr />
      {post.frontmatter.language == "pt" ? <span className="open-get-touch sans ">Enviar um comentário</span> : <span className="open-get-touch sans ">Send A Comment</span>}
      </footer>



    </article>
    <nav className="blog-post-nav global-wrapper">
      <ul
        style={{
          display: `flex`,
          flexWrap: `wrap`,
          justifyContent: `space-between`,
          listStyle: `none`,
          padding: 0,
        }}
      >
        <li>
          {previous && (
            <Link to={previous.fields.slug} rel="prev">
              ← {previous.frontmatter.title}
            </Link>
          )}
        </li>
        <li>
          {next && (
            <Link to={next.fields.slug} rel="next">
              {next.frontmatter.title} →
            </Link>
          )}
        </li>
      </ul>
    </nav>
  </Layout>
 )

}




export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        category
        language
      }
      tableOfContents
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`




/*


line 60 - to implement the signature side and scroller:

<div className="signature-fmcorreia-blog">
    <p>FMCorreia</p>
    <span className="progress-blog-post">

    </span>
</div>
*/